<template>
  <v-dialog @click:outside="$emit('update:dialog', false)" v-model="dialog" width="500">
    <v-card>
      <v-card-title class="primary--text px-4 py-1 text-body-1">
        <v-icon small color="error" class="mx-2">mdi-alert</v-icon>
        {{ $t("auxiliaryTable.alert") }}
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-card-text class="text-body-1 mt-4">
        {{ alertConfirmation ? types === "Income" ? alertConfirmation : alertConfirmation.message : "" }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AlertConfirmationDialog",
  props: ["dialog", "alertConfirmation", "types"],
};
</script>